import React from 'react'
import styled from 'styled-components'
import SuccessImage from '../images/success.jpg'

const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #232323;
  justify-content: center;
  align-items: center;
`

const Heading = styled.h1`
  font-size: 8vh;
  font-weight: bold;
  padding: 0 5%;
  color: white;
`

const SubHeading = styled.h3`
  font-size: 2.5vh;
  font-weight: bold;
  padding: 0 5%;
  color: white;
`

const Link = styled.a`
  color: #ff9000;
  font-weight: bold;
  padding: 0 5%;
  font-size: 2.2vh;
`
const SuccessFreelancer = () => (
  <Wrapper>
    <Heading>Success!</Heading>
    <img src={SuccessImage} alt="success" style={{ width: '50%' }} />
    <SubHeading>
      Your information has been saved, we will be in touch with opportunities
      shortly!
    </SubHeading>
    <Link href="/">Back to Home page</Link>
  </Wrapper>
)

export default SuccessFreelancer
